import React, {useEffect, useState} from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "../../../components/ArgonInput";
import Table from "../../../components/Table";
import ArgonSelect from "../../../components/ArgonSelect";
import ArgonButton from "../../../components/ArgonButton";
import {
    connectToGitHub,
    connectToGitLab,
    getBranchesGitLabByRepositoryId, getBranchReposGitHub, getReposGitHub
} from "../../../api/BackendApi/Account";
import {LoadingButton} from "@mui/lab";
import {TestConfig} from "../../../api/BackendApi/TestConfiguration";
import {getAllIntegration} from "../../../api/BackendApi/Account";
import Divider from "@mui/material/Divider";

function OtherInformation({user, table, selectedTypeTest, binaryTest,commandTest,urlRepository,tokenCloudRepository, orgCloudRepository, projectCloudRepository,checkedPushInBranch,onSelect}) {

    const [checked, setChecked] = React.useState(false);
    const [showBranch, setShowBranch] = React.useState(false);
    const [connectToGitRepository, setConnectToGitRepository] = React.useState(false);
    const [isConnectedToGitRepository, setIsConnectedToGitRepository] = React.useState(false);
    const [errorGitRepository, setErrorGitRepository] = React.useState(false);

    const [cloudTypeRepository, setCloudTypeRepository] = useState([
        { value: 'GitLab', label: 'GitLab'},
        { value: 'GitHub', label: 'GitHub'}
    ]);
    const [showDataAccount, setShowDataAccount] = useState(false);

    const [cloudRepository, setCloudRepository] = useState(null);
    const [cloudRepositoryBranches, setCloudRepositoryBranches] = useState(null);
    const [cloudType, setCloudType] = useState(null);

    useEffect(() => {
        async function fetchData() {
            onSelect.setBinaryTest("");
            onSelect.setCommandTest("");

            const response = await TestConfig.getConfigsByName("Project");
            if (response.status === 200) {
                if(response.data.Project !== undefined) {
                    if(response.data.Project.length > 0){
                        if(selectedTypeTest === "functional"){
                            onSelect.setBinaryTest(response.data.Project[0].name + ".zip");
                            onSelect.setCommandTest("java -jar " + response.data.Project[0].name+ ".jar");
                        }
                    }
                }
            }

            onSelect.setUrlRepository(null);
            onSelect.setTokenCloudRepository(null);
            onSelect.setOrgCloudRepository(null);
            onSelect.setProjectCloudRepository(null);
            onSelect.setOtherArgsTest("");
            onSelect.setSelectedRepository(null);
            onSelect.setSelectedBranchRepository(null);
            onSelect.setCheckedPushInBranch(false);
        }

        fetchData().then(r => console.log("Other Information loaded"))

    }, []);

    const handleChange = async (event) => {
        if (!isConnectedToGitRepository) {
            onSelect.setUrlRepository(null);
            onSelect.setTokenCloudRepository(null);
            onSelect.setOrgCloudRepository(null);
            onSelect.setProjectCloudRepository(null);
        }
        onSelect.setSelectedRepository(null);
        onSelect.setSelectedBranchRepository(null);
        onSelect.setCheckedPushInBranch(true);
        setShowBranch(false)
        setErrorGitRepository(false)
        setShowDataAccount(false)
        setChecked(event.target.checked);
    };

    const handleChangePushInBranch = (event) => {
        onSelect.setCheckedPushInBranch(event.target.checked);
    };

    const handleSelectCloudRepository = async (e) => {
        setShowBranch(false)
        if (e !== null) {
            onSelect.setSelectedRepository(e.value)
            try{
                let response;
                if(cloudType === "GitLab"){
                    response = await getBranchesGitLabByRepositoryId(urlRepository,tokenCloudRepository,e.value);
                }else if(cloudType === "GitHub"){
                    response = await getBranchReposGitHub(urlRepository,tokenCloudRepository,e.owner,e.label);
                }

                if (response.status === 200) {
                    if(response.data){
                        let branchProject = []
                        for (let data of response.data) {
                            branchProject.push({ value: data.name, label: data.name})
                        }
                        setCloudRepositoryBranches(branchProject)
                        setShowBranch(true)
                    }
                }else{
                    setShowBranch(false)
                }
            }catch (err) {
                setShowBranch(false)
            }
        } else {
            onSelect.setSelectedRepository(null)
            setCloudRepositoryBranches(null)
        }
    };

    const handleSelectCloudRepositoryBranches = (e) => {
        if(e!==null)
            onSelect.setSelectedBranchRepository(e.value)
        else
            onSelect.setSelectedBranchRepository(null)
    };

    const handleSelectCloudRepositoryType = async (e) => {
        if (e !== null) {
            onSelect.setCloudTypeSelected(e.value)
            setCloudType(e.value)
            onSelect.setUrlRepository(null);
            onSelect.setTokenCloudRepository(null);
            onSelect.setOrgCloudRepository(null);
            onSelect.setProjectCloudRepository(null);

            let getIntegration = await getAllIntegration(user);

            if (getIntegration.data.account.length > 0) {
                let checkIntegrationData = false
                for (let integration of getIntegration.data.account) {
                    if (integration.integration === e.value){
                        checkIntegrationData = true
                        onSelect.setUrlRepository(integration.url);
                        onSelect.setTokenCloudRepository(integration.token);
                        onSelect.setOrgCloudRepository(integration.org);
                        onSelect.setProjectCloudRepository(integration.project);
                        if(e.value === "GitLab")
                            await handleConnectToGitLabRepo(integration.url, integration.token, integration.project);
                        else if(e.value === "GitHub")
                            await handleConnectToGitHubRepo(integration.url, integration.token, integration.org, integration.project);
                    }
                }
                if(!checkIntegrationData)
                    setShowDataAccount(true)
            } else {
                setShowDataAccount(true)
            }
        }else {
            onSelect.setCloudTypeSelected(null)
            setCloudType(null)
        }
    };

    const handleConnectToGit = async () => {
        if (cloudType === "GitLab")
            await handleConnectToGitLabRepo(urlRepository, tokenCloudRepository, projectCloudRepository);
        else if (cloudType === "GitHub")
            await handleConnectToGitHubRepo(urlRepository, tokenCloudRepository, orgCloudRepository, projectCloudRepository);
    };

        const handleConnectToGitLabRepo = async (url, token, project) => {
            setErrorGitRepository(false)
            setConnectToGitRepository(true)
            try {
                const response = await connectToGitLab(url, token);
                if (response.status === 200) {
                    if (response.data) {
                        let dataProject = []
                        for (let data of response.data) {
                            if(project === "" || project === null)
                                dataProject.push({value: data.id, label: data.name})
                            else{
                                if(data.name === project)
                                    dataProject.push({value: data.id, label: data.name})
                            }
                        }
                        setCloudRepository(dataProject)
                    }
                    setIsConnectedToGitRepository(true)
                    setConnectToGitRepository(false)
                } else {
                    setErrorGitRepository(true)
                    setConnectToGitRepository(false)
                    setShowDataAccount(true)
                }
            } catch (err) {
                setErrorGitRepository(true)
                setConnectToGitRepository(false)
                setShowDataAccount(true)
            }
        };

    const handleConnectToGitHubRepo = async (url,token,org,project) => {
        setErrorGitRepository(false)
        setConnectToGitRepository(true)
        try {
            const response = await connectToGitHub(url, token);
            if (response.status === 200) {
                if (org !== null) {
                    let dataProject = []
                    const response_repo = await getReposGitHub(url, token, org);
                    if (response_repo.status === 200) {
                        for (let data of response_repo.data) {
                            if(project === "" || project === null)
                                dataProject.push({value: data.id, label: data.name, owner: data.owner.login})
                            else{
                                if(data.name === project)
                                    dataProject.push({value: data.id, label: data.name, owner: data.owner.login})
                            }
                        }
                        setCloudRepository(dataProject)
                        setIsConnectedToGitRepository(true)
                        setConnectToGitRepository(false)
                    }
                }else{
                    setConnectToGitRepository(false)
                    setShowDataAccount(true)
                }
            } else {
                setErrorGitRepository(true)
                setConnectToGitRepository(false)
                setShowDataAccount(true)
            }
        } catch (err) {
            setErrorGitRepository(true)
            setConnectToGitRepository(false)
            setShowDataAccount(true)
        }
    };


        const loadingTable = () => {
            let dataTable = [];
            for (let i in table.getSelectedRowModel().rows) {
                let name_in_table = null
                if(table.getSelectedRowModel().rows[i].original.test_name)
                    name_in_table = table.getSelectedRowModel().rows[i].original.test_name
                else
                    name_in_table = table.getSelectedRowModel().rows[i].original.feature_name

                dataTable.push({
                    name: name_in_table,
                });
            }

            return (<Table
                columns={[
                    {name: "name", align: "center"},
                ]}
                rows={dataTable}
            />)
        };

        let body = loadingTable();

        return (
            <ArgonBox>
                <ArgonBox width="80%" textAlign="center" mx="auto" mb={2}>
                    <ArgonBox mb={1}>
                        <ArgonTypography variant="h5" fontWeight="regular">
                            Others Information
                        </ArgonTypography>
                    </ArgonBox>
                    <ArgonTypography variant="body2" fontWeight="regular" color="text">
                        Set the optionals information for the new tests case that will be created
                    </ArgonTypography>
                </ArgonBox>
                <ArgonBox component="form" role="form" mt={2}>
                    <Grid container columnSpacing={3}>
                        <Grid item xs={12} md={5} sx={{maxHeight: '15em', overflow: "auto"}}>
                            {body}
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={12}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6} md={6}>
                                            <ArgonTypography fontSize={14}>Binaries</ArgonTypography>
                                            <ArgonBox>
                                                <ArgonInput
                                                    placeholder="Eg. Project.zip"
                                                    size="medium"
                                                    value={binaryTest==null ? "" : binaryTest}
                                                    onChange={(e) => onSelect.setBinaryTest(e.target.value !== "" ? e.target.value : null)}
                                                />
                                            </ArgonBox>
                                        </Grid>
                                        <Grid item xs={6} md={6}>
                                            <ArgonTypography fontSize={14}>Command</ArgonTypography>
                                            <ArgonBox>
                                                <ArgonInput
                                                    placeholder="Eg. java -jar Project.jar"
                                                    size="medium"
                                                    value={commandTest==null ? "" : commandTest}
                                                    onChange={(e) => onSelect.setCommandTest(e.target.value !== "" ? e.target.value : null)}
                                                />
                                            </ArgonBox>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <ArgonTypography fontSize={14}>Arguments</ArgonTypography>
                                    <ArgonBox>
                                        <ArgonInput
                                            placeholder="Arguments"
                                            size="medium"
                                            onChange={(e) => onSelect.setOtherArgsTest(e.target.value !== "" ? e.target.value : null)}
                                        />
                                    </ArgonBox>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <FormControl component="fieldset" sx={{ml: 2}}>
                                        <FormControlLabel
                                            checked={checked}
                                            control={<Checkbox/>}
                                            label="Connect to Repository Git"
                                            onChange={handleChange}
                                        />
                                    </FormControl>
                                </Grid>
                                {checked &&
                                    <>
                                        {!isConnectedToGitRepository ?
                                            <>
                                                <Grid item xs={12} md={12}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6} md={6}>
                                                            <ArgonTypography fontSize={14}>Git Repository</ArgonTypography>
                                                            <ArgonSelect
                                                                placeholder="Select Git Repository"
                                                                isSearchable
                                                                options={cloudTypeRepository}
                                                                onChange={(e) => handleSelectCloudRepositoryType(e)}
                                                            />
                                                        </Grid>

                                                        {showDataAccount &&
                                                            <Grid item xs={6} md={6}>
                                                                <ArgonTypography fontSize={14}>URL
                                                                    Repository</ArgonTypography>
                                                                <ArgonBox>
                                                                    <ArgonInput
                                                                        type="url"
                                                                        placeholder="Insert Url Repository"
                                                                        size="medium"
                                                                        value={urlRepository === null ? "" : urlRepository}
                                                                        onChange={(e) => onSelect.setUrlRepository(e.target.value !== "" ? e.target.value : null)}
                                                                    />
                                                                </ArgonBox>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Grid>
                                                {showDataAccount &&
                                                    <>
                                                        <Grid item xs={6} md={6}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} md={12}>
                                                                    <ArgonTypography fontSize={14}>Token</ArgonTypography>
                                                                    <ArgonBox mb={2}>
                                                                        <ArgonInput
                                                                            placeholder="Insert Token"
                                                                            size="medium"
                                                                            value={tokenCloudRepository === null ? "" : tokenCloudRepository}
                                                                            onChange={(e) => onSelect.setTokenCloudRepository(e.target.value !== "" ? e.target.value : null)}
                                                                        />
                                                                    </ArgonBox>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        {cloudType === "GitHub" &&
                                                            <Grid item xs={6} md={6}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} md={12}>
                                                                        <ArgonTypography fontSize={14}>Organization</ArgonTypography>
                                                                        <ArgonBox mb={2}>
                                                                            <ArgonInput
                                                                                placeholder="Insert Organization Name"
                                                                                size="medium"
                                                                                value={orgCloudRepository === null ? "" : orgCloudRepository}
                                                                                onChange={(e) => onSelect.setOrgCloudRepository(e.target.value !== "" ? e.target.value : null)}
                                                                            />
                                                                        </ArgonBox>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        }
                                                        <Grid item xs={12} md={12}>
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={2} md={2}>
                                                                    {!connectToGitRepository ? (<ArgonButton
                                                                            variant="gradient"
                                                                            color="info"
                                                                            disabled={cloudType === null || urlRepository === null || tokenCloudRepository === null || (cloudType === "GitHub" && orgCloudRepository === null)}
                                                                            onClick={(e) => handleConnectToGit()}
                                                                            size="medium">Connect</ArgonButton>)
                                                                        : (<LoadingButton color="secondary" loading
                                                                                          variant="contained">Connect</LoadingButton>)}
                                                                </Grid>
                                                                {errorGitRepository &&
                                                                    <Grid item xs={10} md={10} m={"auto"}>
                                                                        <ArgonTypography color={"error"} fontWeight={"bold"}
                                                                                         fontSize={14}>Error! Credentials are wrong or URL not reachable</ArgonTypography>
                                                                    </Grid>
                                                                }
                                                            </Grid>
                                                        </Grid>
                                                    </>
                                                }
                                            </>
                                            :
                                            <>
                                                <Divider></Divider>
                                                <Grid item xs={10} md={10} sx={{minHeight: "11em"}}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={6} md={6}>
                                                            <ArgonTypography fontSize={14}>Git
                                                                Projects</ArgonTypography>
                                                            <ArgonSelect
                                                                placeholder="Select Project"
                                                                isSearchable
                                                                isClearable
                                                                options={cloudRepository}
                                                                onChange={(e) => handleSelectCloudRepository(e)}
                                                            />
                                                        </Grid>
                                                        {showBranch &&
                                                            <>
                                                                <Grid item xs={6} md={6}>
                                                                    <ArgonTypography
                                                                        fontSize={14}>Branches</ArgonTypography>
                                                                    <ArgonSelect
                                                                        placeholder="Select Branch"
                                                                        isSearchable
                                                                        isClearable
                                                                        options={cloudRepositoryBranches}
                                                                        onChange={(e) => handleSelectCloudRepositoryBranches(e)}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={12}>
                                                                    <ArgonTypography fontSize={14}>If Branch is empty
                                                                        create new branch</ArgonTypography>
                                                                </Grid>
                                                                <Grid item xs={12} md={12}>
                                                                    <FormControl component="fieldset" sx={{ml: 2}}>
                                                                        <FormControlLabel
                                                                            checked={checkedPushInBranch}
                                                                            control={<Checkbox/>}
                                                                            label="Push in branch"
                                                                            onChange={handleChangePushInBranch}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                            </>
                                                        }
                                                    </Grid>
                                                </Grid>
                                            </>
                                        }
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </ArgonBox>
            </ArgonBox>
        );
    }

export default OtherInformation;