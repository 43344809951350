import apiClient from "../index";

const pushCloudRepository = (data) => apiClient.post("/be/management-tool/cloudRepository/push", data)

const getListFromProjectJira = (data) => apiClient.post("/be/management-tool/jira/getList", data)

export {
  pushCloudRepository,
  getListFromProjectJira
};
