// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Images
import logo from "assets/images/small-logos/logo-gitlab.svg";

// Argon Dashboard 2 PRO MUI contexts
import { useArgonController } from "context";
import {Accordion, AccordionDetails, AccordionSummary, InputAdornment, tooltipClasses} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {addIntegration} from "../../../../api/BackendApi/Account";
import {enqueueSnackbar} from "notistack";
import React, {useState} from "react";
import {connectToGitLab} from "../../../../api/BackendApi/Account";
import {LoadingButton} from "@mui/lab";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from '@mui/icons-material/Error';
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import {Info, Visibility, VisibilityOff} from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import {styled} from "@mui/material/styles";

function Gitlab({user,account,setAccount}) {
    const [controller] = useArgonController();
    const { darkMode } = controller;

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {event.preventDefault();};

    const [check, setCheck] = useState(false);
    const [checkError, setCheckError] = useState(false);

    const [checkSuccess, setCheckSuccess] = useState(false);

    const NoMaxWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 'none',
        },
    });

    const handleSaveIntegration = async () => {
        const integrationInfo= {
            url: account.gitLab.url,
            token: account.gitLab.token,
            project: account.gitLab.project,
            lastSaved: moment().format('MM/DD/YYYY HH:mm:ss'),
        }

        setAccount.setGitLab(integrationInfo)

        const response = await addIntegration(user,"GitLab",integrationInfo)
        enqueueSnackbar('Credential GitLab saved!',{variant:'success'})
    }

    const handleCheckIntegration = async () => {
        try{
            setCheck(true)
            setCheckError(false)
            const response = await connectToGitLab(account.gitLab.url,account.gitLab.token);
            setCheck(false)
            setCheckError(true)
            if (response.status === 200) {
                setCheckSuccess(true)
            }
        }catch (err) {
            setCheck(false)
            setCheckError(true)
            setCheckSuccess(false)

            console.log(err)
        }
    }

    const handleChangeValue = (val,name) => {
        if(name === "url")
            setAccount.setGitLab({...account.gitLab,url:val})
        if(name === "token")
            setAccount.setGitLab({...account.gitLab,token:val})
        if(name === "project")
            setAccount.setGitLab({...account.gitLab,project:val})
    }

    return (
        <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content-gitlab"
              id="panel-gitlab"
            >
                <ArgonBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  flexDirection={{ xs: "column", sm: "row" }}
                >
                    <ArgonBox display="flex" alignItems="center">
                        <ArgonAvatar src={logo} alt="Gitlab logo" variant="rounded" />
                        <ArgonBox ml={2}>
                            <ArgonTypography variant="h5" fontWeight="medium">
                                GitLab
                            </ArgonTypography>
                        </ArgonBox>
                    </ArgonBox>
                </ArgonBox>
            </AccordionSummary>
            <AccordionDetails>
                <ArgonBox ml={2} pl={6} pt={2} lineHeight={1}>
                    <ArgonBox
                        bgColor={darkMode ? "transparent" : "grey-100"}
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                        my={3}
                        py={1}
                        pl={{ xs: 1, sm: 2 }}
                        pr={1}
                        mt={0}
                    >
                        <ArgonTypography variant="button" fontWeight="medium" color="text">
                            Url to your GitLab
                        </ArgonTypography>
                        <ArgonBox width={{ xs: "100%", sm: "50%", md: "50%" }} mt={{ xs: 1, sm: 0 }}>
                            <ArgonInput
                                type="url"
                                size="medium"
                                placeholder="https://<YOUR_HOST>/"
                                value={account.gitLab.url}
                                onChange={(e) => {handleChangeValue(e.target.value!=="" ? e.target.value : null,"url")}}/>
                        </ArgonBox>
                    </ArgonBox>
                    <ArgonBox
                        bgColor={darkMode ? "transparent" : "grey-100"}
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                        my={3}
                        py={1}
                        pl={{ xs: 1, sm: 2 }}
                        pr={1}
                    >
                            <ArgonTypography variant="button" fontWeight="medium" color="text">
                                Token to your GitLab
                                <NoMaxWidthTooltip  title="https://docs.gitlab.com/ee/user/profile/personal_access_tokens.html#create-a-personal-access-token" placement="top">
                                    <Icon>
                                        <Info></Info>
                                    </Icon>
                                </NoMaxWidthTooltip >
                            </ArgonTypography>
                        <ArgonBox width={{ xs: "100%", sm: "50%", md: "50%" }} mt={{ xs: 1, sm: 0 }}>
                            <ArgonInput
                                size="medium"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Add your Token"
                                autoComplete="off"
                                value={account.gitLab.token}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                onChange={(e) => {handleChangeValue(e.target.value!=="" ? e.target.value : null,"token")}}/>
                        </ArgonBox>
                    </ArgonBox>
                    <ArgonBox
                        bgColor={darkMode ? "transparent" : "grey-100"}
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                        my={3}
                        py={1}
                        pl={{ xs: 1, sm: 2 }}
                        pr={1}
                        mt={0}
                    >
                        <ArgonTypography variant="button" fontWeight="medium" color="text">
                            Project (optional)
                        </ArgonTypography>
                        <ArgonBox width={{ xs: "100%", sm: "50%", md: "50%" }} mt={{ xs: 1, sm: 0 }}>
                            <ArgonInput
                                type="text"
                                size="medium"
                                placeholder="Project Name"
                                value={account.gitLab.project}
                                onChange={(e) => {handleChangeValue(e.target.value!=="" ? e.target.value : null,"project")}}/>
                        </ArgonBox>
                    </ArgonBox>
                    <ArgonBox
                        bgColor={darkMode ? "transparent" : "grey-100"}
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems={{ xs: "flex-start", sm: "center" }}
                        flexDirection={{ xs: "column", sm: "row" }}
                        my={3}
                        py={1}
                        pl={{ xs: 1, sm: 2 }}
                        pr={1}
                        pb={0}
                    >
                        <ArgonTypography variant="button" fontWeight="medium" color="text">
                            Verify or Save your GitLab credentials
                        </ArgonTypography>
                        <ArgonBox
                            display="flex"
                            alignItems={{ xs: "flex-start", sm: "center" }}
                            flexDirection={{ xs: "column", sm: "row" }}
                        >
                            {checkError &&
                                <>
                                {checkSuccess ?

                                    <ArgonTypography variant="button" fontWeight="light"  sx={{ mr: 2, color: 'green' }} color="text">
                                        <CheckIcon fontSize="inherit"/> Connection Successful!
                                    </ArgonTypography>
                                    :
                                    <ArgonTypography variant="button" fontWeight="light"  sx={{ mr: 2, color: 'red' }} color="text">
                                        <ErrorIcon fontSize="inherit"/> Connection Refused!
                                    </ArgonTypography>
                                }
                                </>
                            }
                            {!check ?
                                <ArgonButton variant="gradient" color="primary" sx={{ mr: 2 }} size="small" onClick={handleCheckIntegration} disabled={account.gitLab.url==null || account.gitLab.token==null}>
                                    Check
                                </ArgonButton>
                                :
                                <LoadingButton color="primary" loading variant="contained"  sx={{ mr: 2 }}  size="small">Check</LoadingButton>
                            }
                            <ArgonButton variant="gradient" color="success" size="small" onClick={handleSaveIntegration} disabled={account.gitLab.url==null || account.gitLab.token==null}>
                                Save
                            </ArgonButton>
                        </ArgonBox>
                    </ArgonBox>
                    <ArgonBox textAlign="end">
                        {account.gitLab.lastSaved==null ?
                            <ArgonTypography variant="button" fontWeight="light" verticalAlign={"middle"} sx={{ mr: 2 }} color="text">
                                Last Saved: Never
                            </ArgonTypography>
                            :
                            <ArgonTypography variant="button" fontWeight="light" verticalAlign={"middle"} sx={{ mr: 2 }} color="text">
                                Last Saved: {account.gitLab.lastSaved}
                            </ArgonTypography>
                        }

                    </ArgonBox>
                </ArgonBox>
          </AccordionDetails>
        </Accordion>
  );
}

export default Gitlab;
