import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import FolderIcon from '@mui/icons-material/Folder';
import CodeIcon from '@mui/icons-material/Code';

import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import SyncIcon from '@mui/icons-material/Sync';
import { TreeItem, TreeView } from "@mui/x-tree-view";
import { Checkbox, CircularProgress, tooltipClasses } from '@mui/material';
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";

import ArgonBox from "components/ArgonBox";
import AddNewFolderView from "pages/test-repository/components/TestTree/components/AddNewFolderView";
import AddNewTestView from "pages/test-repository/components/TestTree/components/AddNewTestView";

import { testRepository, deleteEntity, exportFolder } from "api/BackendApi/TestRepository";
import ArgonTypography from "components/ArgonTypography";
import { enqueueSnackbar } from "notistack";
import { Clear, ContentCopy, ContentCut, ContentPaste, Download, Edit, Upload } from "@mui/icons-material";

import Tooltip from "@mui/material/Tooltip";
import styled from "@emotion/styled";
import UploadFileDialog from "../UploadFileDialog";
import TestRepository from "pages/test-repository/TestRepository";

function TestTree({
  onSelect,
  onSelectName,
  onMultiSelect = null,
  checkboxes = false,
  showHeaderButtons = true,
  height = "calc(100vh - 8vh)",
}) {

  const buttonStyleLeft = ({ functions: { pxToRem } }) => ({
    width: pxToRem(34),
    minWidth: pxToRem(34),
    height: pxToRem(34),
    minHeight: pxToRem(34),
    mr: 1
  });

  const buttonStyleRight = ({ functions: { pxToRem } }) => ({
    width: pxToRem(34),
    minWidth: pxToRem(34),
    height: pxToRem(34),
    minHeight: pxToRem(34),
    ml: 1
  });

  const [expandedTreeFolders, _setExpandedTreeFolders] = useState(JSON.parse(sessionStorage.getItem("repotreecache") ?? "[\"folder-root\"]"));

  // State definition
  const [treeTextFilter, setTreeTextFilter] = useState("");
  const [addTestDialogOpen, setAddTestDialogOpen] = useState(false);
  const [addFolderDialogOpen, setAddFolderDialogOpen] = useState(false);
  const [selectedFolderId, _setSelectedFolderId] = useState(sessionStorage.getItem("reposelfldid"));
  const [selectedTreeNode, _setSelectedTreeNode] = useState(sessionStorage.getItem("reposeltreenode"));

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [repository, setRepository] = useState(null);

  const [isClearing, setIsClearing] = useState(false);
  const [isCopying, setIsCopying] = useState(false);
  const [isCutting, setIsCutting] = useState(false);
  const [isPasting, setIsPasting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const [enableEdit, setEnableEdit] = useState(true);
  const [enableClear, setEnableClear] = useState(false);
  const [enableCopy, setEnableCopy] = useState(false);
  const [enableCut, setEnableCut] = useState(false);
  const [enablePaste, setEnablePaste] = useState(false);

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const [useCheckboxed, setUseCheckboxes] = useState(checkboxes);

  const setSelectedFolderId = (value) => {
    sessionStorage.setItem("reposelfldid", value);
    _setSelectedFolderId(value);
  };

  const setSelectedTreeNode = (value) => {
    sessionStorage.setItem("reposeltreenode", value);
    _setSelectedTreeNode(value);
  };

  const setExpandedTreeFolders = (value) => {
    sessionStorage.setItem("repotreecache", JSON.stringify(value));
    _setExpandedTreeFolders(value);
  };

  const handleEnableEdit = () => {
    setEnableEdit(false);
    setEnableClear(true);
    setEnableCopy(true);
    setEnableCut(true);
    setEnablePaste(true);
    setUseCheckboxes(true);
  };


  const handleDisableEdit = () => {
    setEnableEdit(true);
    setEnableClear(false);
    setEnableCopy(false);
    setEnableCut(false);
    setEnablePaste(false);
    setUseCheckboxes(false);
    setSelectedOptions([]);
  };

  const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

  const downloadFile = (name, text, content_type) => {
    const element = document.createElement("a");
    const file = new Blob([text], { type: content_type });
    element.id = name
    element.href = URL.createObjectURL(file);
    element.download = name;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element);
  }


  const importTestCases = () => {
    setUploadDialogOpen(true);
  };

  const exportTestCases = async () => {
    var result = await exportFolder(selectedFolderId);
    if (result.data.ok === true) {
      downloadFile(result.data.name, result.data.csv, 'text/csv');
      enqueueSnackbar("Exported " + result.data.name, { variant: "success" })
    } else {
      enqueueSnackbar(result.data.reason, { variant: "error" })
    }
  };


  const onImportCompleted = () => {
    setUploadDialogOpen(false);
    addLastSelectedId();
    refreshTree();
  };

  const addExpandedNodeId = (nodeId) => {
    setExpandedTreeFolders([nodeId, ...expandedTreeFolders]);
  }

  const removeExpandedNodeId = (nodeId) => {
    let index = expandedTreeFolders.indexOf(nodeId);
    while (index > -1) {
      expandedTreeFolders.splice(index, 1);
      index = expandedTreeFolders.indexOf(nodeId);
    }
    setExpandedTreeFolders(expandedTreeFolders);
    // onNodeSelect(event, nodeId)
  }

  const deleteNode = async () => {
    setIsDeleting(true);
    var deleted = false;
    try {
      let id = selectedTreeNode.split('-')[1];
      let response = await deleteEntity(id);
      if (response.data.deleted > 0) {
        enqueueSnackbar("Deleted!", { variant: "success" });
        deleted = true;
      } else {
        enqueueSnackbar(response.data.reason, { variant: "error" });
      }

    } finally {
      setIsDeleting(false);
      setSelectedTreeNode(null);
      setSelectedFolderId(null);
      if (deleted) await refreshTree();
    }
  }

  const refreshTree = () => {
    setRepository(null);
    return testRepository()
      .then((d) => setRepository(d.data.repository))
      // Show error
      .catch((e) => console.log(e));
  };

  const handleClose = () => {
    setAddTestDialogOpen(false);
    setAddFolderDialogOpen(false);
    refreshTree();
  };

  const toggleSelectedNode = (node) => {
    var nodeIds = [];
    var allSelected = false;
    var isMultiToggle = false;
    if (node.type == "folder") {
      for (var test of repository) {
        if (test.parent == node._id) {
          nodeIds.push(test._id);
        }
      }
      isMultiToggle = true;
      allSelected = nodeIds.map(n => selectedOptions.indexOf(n) != -1).every(b => b === true);
    } else {
      nodeIds.push(node._id);
    }
    var addOnly = !isMultiToggle || !allSelected;
    var removeOnly = !isMultiToggle || allSelected;
    var newOptions = [...selectedOptions];
    for (var nodeId of nodeIds) {
      let index = newOptions.indexOf(nodeId);
      if (index != -1) {
        if (removeOnly) {
          newOptions.splice(index, 1);
        }
      } else {
        if (addOnly) {
          newOptions.push(nodeId);
        }
      }
    }
    console.log(newOptions);
    onMultiSelect?.(newOptions.filter(id => repository.find(t => t._id == id).type != "folder"));
    setSelectedOptions(newOptions);
  }

  const onNodeSelect = (e, id) => {
    e.stopPropagation();
    setSelectedTreeNode(id);

    let node = repository.find(c => c._id === id.split("-")[1]);
    if (node == null) {
      setSelectedFolderId(id.split("-")[1]);
      onSelectName?.("Root");
    } else {
      if (id.startsWith("folder-")) {
        onSelectName?.(node.name);
        setSelectedFolderId(id.split("-")[1]);
      } else {
        setSelectedFolderId(null);
      }
    }

    onSelect?.(id);
  };

  // Function definitions
  const getTreeItemsFromData = (currentId, children = []) => {
    if (children === null) {
      return [];
    }

    let itemChildren = undefined;

    for (let child of children) {
      if (child.parent === currentId) {
        if (itemChildren === undefined) {
          itemChildren = [];
        }
        itemChildren = itemChildren.concat(getTreeItemsFromData(child._id, children)).filter(e => e !== null);
      }
    }

    if (currentId === "root") return itemChildren;

    let current = children.find(c => c._id === currentId);
    if (treeTextFilter.length > 0) {
      if (current.type === "test" && !current.name.toLowerCase().includes(treeTextFilter.toLowerCase())) {
        return null;
      }
    }

    var selected = false;
    if (current.type == "folder") {
      selected = children.filter(c => c.parent == current._id).map(c => selectedOptions.indexOf(c._id) != -1).every(e => e === true);
    } else {
      selected = selectedOptions.indexOf(current._id) != -1;
    }

    let nodeId = `${current.type}-${current._id}`;

    return <TreeItem
      key={current._id}
      nodeId={nodeId}
      collapseIcon={<ExpandMoreIcon onClick={(e) => removeExpandedNodeId(nodeId)} />}
      expandIcon={<ChevronRightIcon onClick={(e) => addExpandedNodeId(nodeId)} />}
      label={
        <div onClick={event => { onNodeSelect(event, `${current.type}-${current._id}`) }}>
          <Grid container wrap="nowrap" spacing="3" alignItems="center">
            {useCheckboxed && <Grid key={0} item >
              <Checkbox checked={selected} onChange={() => toggleSelectedNode(current)}></Checkbox>
            </Grid>}
            <Grid key={1} item >
              {current.type === "folder" ? <FolderIcon sx={() => ({ mt: 1 })} /> : <CodeIcon sx={() => ({ mt: 1 })} />}
            </Grid>
            <Grid key={2} item zeroMinWidth>
              <ArgonTypography fontSize={16}>
                {current.name}
              </ArgonTypography>
            </Grid>
          </Grid></div>

      } >{itemChildren}</TreeItem>;
  };

  useEffect(() => {
    refreshTree();
  }, []);

  // Loading indicator
  let content = <Stack direction='row' justifyContent='center'>
    <CircularProgress />
  </Stack>;


  // Loaded content
  if (repository != null) {

    content = (
      <TreeView
        sx={{
          height: "100%",
          flexGrow: 1,
          overflow: "auto"
        }}
        // focusedNodeId={selectedTreeNode}
        defaultExpanded={expandedTreeFolders}
        selected={[selectedTreeNode]}
        aria-label="file system navigator"
      >

        <TreeItem
          key={"root"}
          nodeId={`folder-root`}
          collapseIcon={<ExpandMoreIcon onClick={(e) => removeExpandedNodeId(`folder-root`)} />}
          expandIcon={<ChevronRightIcon onClick={(e) => addExpandedNodeId(`folder-root`)} />}
          label={
            <div onClick={event => { onNodeSelect(event, `folder-root`) }}>
              <Grid container spacing="3" alignItems="center">
                <Grid key={0} item>
                  <FolderIcon sx={() => ({ mt: 1 })} />
                </Grid>
                <Grid key={1} item>
                  <ArgonTypography fontSize={16} >
                    Root
                  </ArgonTypography>
                </Grid>
              </Grid>
            </div>
          }>
          {getTreeItemsFromData("root", repository)}
        </TreeItem>

      </TreeView>
    );
  }

  let headerButtons = (
    <ArgonBox p={2}>
      <Grid container spacing={3}>
        <Grid item xs={6} md={6}>
          <Box display="flex" flexDirection="column" alignItems="start">
            <ArgonBox>
              <ArgonButton variant="contained" color="info" size="large" iconOnly sx={buttonStyleLeft} onClick={refreshTree}>
                <NoMaxWidthTooltip title="Refresh" placement="top">
                  <SyncIcon />
                </NoMaxWidthTooltip>
              </ArgonButton>
              {window.user.canImportTestRepo() && <ArgonButton variant="contained" color="primary" size="large" iconOnly disabled={selectedFolderId == null} sx={buttonStyleLeft} onClick={importTestCases}>
                <NoMaxWidthTooltip title="Import Test Cases" placement="top">
                  <Upload />
                </NoMaxWidthTooltip>
              </ArgonButton>}
              {window.user.canExportTestRepo() && <ArgonButton variant="contained" color="primary" size="large" iconOnly disabled={selectedFolderId == null} sx={buttonStyleLeft} onClick={exportTestCases}>
                <NoMaxWidthTooltip title="Export Test Cases" placement="top">
                  <Download />
                </NoMaxWidthTooltip>
              </ArgonButton>}
              {window.user.canCreateTestRepo() && <ArgonButton iconOnly variant="contained" color="primary" size="large" sx={buttonStyleLeft} disabled={selectedFolderId == null} onClick={setAddTestDialogOpen}>
                <NoMaxWidthTooltip title="New Test Case" placement="top">
                  <AddIcon />
                </NoMaxWidthTooltip>
              </ArgonButton>}
              <ArgonButton iconOnly disabled={selectedFolderId == null} variant="contained" color="primary" size="large" sx={buttonStyleLeft} onClick={setAddFolderDialogOpen}>
                <NoMaxWidthTooltip title="New Folder" placement="top">
                  <CreateNewFolderIcon />
                </NoMaxWidthTooltip>
              </ArgonButton>
            </ArgonBox>
          </Box>
        </Grid>
        <Grid item xs={6} md={6}>
          <Box display="flex" flexDirection="column" alignItems="end">
            <ArgonBox>
              {(window.user.canEditTestRepo() && enableEdit) &&
                <ArgonButton variant="contained" color="dark" size="large" iconOnly sx={buttonStyleRight} onClick={handleEnableEdit} disabled={!enableEdit}>
                  <NoMaxWidthTooltip title="Enable Action" placement="top">
                    <Edit />
                  </NoMaxWidthTooltip>
                </ArgonButton>}
              {(window.user.canEditTestRepo() && !enableEdit) &&
                <ArgonButton variant="outlined" color="dark" size="large" iconOnly sx={buttonStyleRight} onClick={handleDisableEdit} disabled={!enableClear}>
                  <NoMaxWidthTooltip title="Disable Action" placement="top">
                    <Clear />
                  </NoMaxWidthTooltip>
                </ArgonButton>
              }
              <ArgonButton variant="contained" color={enableCopy ? "dark" : "secondary"} size="large" iconOnly sx={buttonStyleRight} disabled={!enableCopy}>
                <NoMaxWidthTooltip title="Copy (TODO)" placement="top">
                  <ContentCopy />
                </NoMaxWidthTooltip>
              </ArgonButton>
              <ArgonButton variant="contained" color={enableCopy ? "dark" : "secondary"} size="large" iconOnly sx={buttonStyleRight} disabled={!enableCut}>
                <NoMaxWidthTooltip title="Cut (TODO)" placement="top">
                  <ContentCut />
                </NoMaxWidthTooltip>
              </ArgonButton>
              <ArgonButton variant="contained" color={enableCopy ? "dark" : "secondary"} size="large" iconOnly sx={buttonStyleRight} disabled={!enablePaste}>
                <NoMaxWidthTooltip title="Paste (TODO)" placement="top">
                  <ContentPaste />
                </NoMaxWidthTooltip>
              </ArgonButton>
              {window.user.canDeleteTestRepo() && <ArgonButton
                iconOnly
                disabled={(selectedTreeNode == null || isDeleting || selectedTreeNode === 'folder-root')}
                variant="contained"
                color="error"
                size="large"
                sx={buttonStyleRight}
                onClick={deleteNode}>
                <NoMaxWidthTooltip title="Delete" placement="top">
                  <DeleteIcon />
                </NoMaxWidthTooltip>
              </ArgonButton>}
            </ArgonBox>
          </Box>
        </Grid>
      </Grid>
    </ArgonBox>
  );
  if (!showHeaderButtons) {
    headerButtons = <Box />;
  }

  return (

    <Card sx={{ display: "flex", height: height }}>
      <UploadFileDialog open={uploadDialogOpen} onClose={() => setUploadDialogOpen(false)} onComplete={onImportCompleted} folderId={selectedFolderId} />
      <Dialog
        open={addTestDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle>{"Create New Test"}</DialogTitle>
        <DialogContent>
          <AddNewTestView closeDialog={handleClose} folderId={selectedFolderId} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={addFolderDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>{"Create New Folder"}</DialogTitle>
        <DialogContent>
          <AddNewFolderView closeDialog={handleClose} folderId={selectedFolderId} />
        </DialogContent>
      </Dialog>

      {headerButtons}
      <ArgonBox p={2}>
        <Grid container>
          <Grid item xs={12}>
            <ArgonInput
              type="text"
              placeholder="Search..."
              size="medium"
              onClick={() => { }}
              onChange={(e) => { setTreeTextFilter(e.target.value) }}
            />
          </Grid>
        </Grid>
      </ArgonBox>
      {content}
    </Card>
  );
}

TestTree.propTypes = {
  onSelect: PropTypes.func,
  onSelectName: PropTypes.func,
  onMultiSelect: PropTypes.func,
  checkboxes: PropTypes.bool,
  showHeaderButtons: PropTypes.bool,
  height: PropTypes.string
};


export default TestTree;
