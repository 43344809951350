import React, {useEffect, useRef, useState} from "react";

import ArgonBox from "../../../components/ArgonBox";
import ArgonTypography from "../../../components/ArgonTypography";

import {ExpandMore, Info, Visibility, VisibilityOff} from "@mui/icons-material";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {Grid, Card, CardHeader, InputAdornment, tooltipClasses, CircularProgress} from "@mui/material";

// Images
import logoJira from "assets/images/small-logos/logo-jira.svg";
import ArgonInput from "../../../components/ArgonInput";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import {styled} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import ErrorIcon from "@mui/icons-material/Error";
import ArgonButton from "../../../components/ArgonButton";
import {LoadingButton} from "@mui/lab";
import {connectToJira} from "../../../api/BackendApi/Account";
import ArgonSelect from "../../../components/ArgonSelect";
import Switch from "@mui/material/Switch";
import DataTable from "../../../components/DataTable";
import {getListFromProjectJira} from "../../../api/BackendApi/microservices/ManagementTool";
import {enqueueSnackbar} from "notistack";

export function JiraTool({userIntegrations, setManagementSend, jira, expandedDialog, module}) {

	const [check, setCheck] = useState(false);
	const [checkError, setCheckError] = useState(false);
	const [checkSuccess, setCheckSuccess] = useState(false);

	const [expanded, setExpanded] = useState(expandedDialog);
	const [showPassword, setShowPassword] = React.useState(false);

	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {event.preventDefault();};

	const [url, setUrl] = useState("");
	const [email, setEmail] = useState("");
	const [token, setToken] = useState("");
	const [projectDefault, setProjectDefault] = useState(null);

	const [projectSelectedJira, setProjectSelectedJira] = useState(null);

	const [dataJira, setDataJira] = useState(null);

	useEffect(() => {
		for (let integration of userIntegrations) {
			if (integration.integration === "Jira"){
				setUrl(integration.url);
				setEmail(integration.email);
				setToken(integration.token);
				if(integration.project && integration.project !== ""){
					setProjectDefault(integration.project);
				}
			}
		}
	}, []);

	const loading = () => {
		return (<ArgonBox mt={10}
			style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
			<CircularProgress size={64} />
		</ArgonBox>);
	};

	const handleExpandClick = () => {
		setExpanded(!expanded);
	};

	const handleSelectProjectJira = async (e) => {
		if (e !== null) {
			setProjectSelectedJira(e.value)
			await loadDataJira(e.value)
		} else
			setProjectSelectedJira(null)
	};

	const loadDataJira = async (project) => {
		try{
			let response = await getListFromProjectJira({"project": project})

			if(response.status === 200){
				if(response.data > 0){
					//TODO
					setDataJira([])
				}else{
					setDataJira([])
				}
			}else{
				console.log(response)
				enqueueSnackbar('Error!',{variant:'error'})
				setDataJira([])
			}
		}catch (err) {
			console.log(err)
			enqueueSnackbar('Error!',{variant:'error'})
			setDataJira([])
		}

	}

	const handleChangeValue = (val,name) => {
		switch (name) {
			case "url":
				setUrl(val);
				break;
			case "email":
				setEmail(val);
				break;
			case "token":
				setToken(val);
				break;
			default:
				break;
		}
	}

	const handleConnectIntegration = async () => {
		try{
			setCheck(true)
			setCheckError(false)
			const response = await connectToJira(url,email,token);
			setCheck(false)
			if (response.status === 200) {
				if(projectDefault!==null){
					jira.setProjectsJira(response.data.values.filter(project => project.key === projectDefault))
				}else{
					jira.setProjectsJira(response.data.values)
				}
				setCheckSuccess(true)
				jira.setConnectedJira(true)
			}else{
				setCheckError(true)
				setCheckSuccess(false)
			}
		}catch (err) {
			setCheck(false)
			setCheckError(true)
			setCheckSuccess(false)

			console.log(err)
		}
	}

	const NoMaxWidthTooltip = styled(({ className, ...props }) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))({
		[`& .${tooltipClasses.tooltip}`]: {
			maxWidth: 'none',
		},
	});

	return (
		<Card>
			<CardHeader
				action={
					<ExpandMore
						expand={expanded}
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more"
					>
						<ExpandMoreIcon />
					</ExpandMore>
				}
				title={
					<Grid container justifyContent="center" alignItems="center">
						<Grid item >
							<ArgonBox>
								<img src={logoJira} style={{width: "3vw", height: "3vw"}} alt="logoJira"/>
							</ArgonBox>
						</Grid>
						<Grid item >
							<ArgonBox>
								<ArgonTypography component="label" variant="h5" fontWeight="bold">
									Jira
								</ArgonTypography>
							</ArgonBox>
						</Grid>
					</Grid>
				}
			/>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
					{
						jira.connectedJira ?
							<CardContent sx={{marginBottom: 25}}>
								<ArgonSelect
									size={"large"}
									placeholder={"Select Project"}
									onChange={(e) => handleSelectProjectJira(e)}
									options={jira.projectsJira.map((project) => {
										return ({
											"label": project.name,
											"value": project.id
										})
									})}
								/>
								{module === "TestResult" ?
									<>
										{projectSelectedJira ?
											<>
												{dataJira===null ?
													loading()
													:
													<>
														{dataJira.length === 0 ?
															<ArgonTypography fontSize={30} fontWeight="bold" sx={{marginTop: 10, textAlign: "center", alignItems: "center", flexDirection: "column" }}>NO DATA</ArgonTypography>
															:
															<DataTable
																entriesPerPage={{
																	defaultValue: 50,
																	entries: []
																}}
																enableSelection={false}
																table={{
																	columns: [
																		{
																			name: "status",
																			label: "Status",
																			options: {
																				filter: true,
																				sort: true
																			}
																		},
																		{
																			name: "key",
																			label: "Key",
																			options: {
																				filter: true,
																				sort: true
																			}
																		}
																	],
																	rows: Array.from(dataJira).map(data => {

																		return ({
																			"status": data.status,
																			"key": data.key,
																		})
																	}),
																}}
															/>
														}
													</>
												}
											</>
											:
											<></>
										}
									</>
									:
									<ArgonTypography >To be Released - Generative</ArgonTypography>
								}
							</CardContent>
							:
							<CardContent>
								<ArgonBox
									bgColor={"grey-100"}
									borderRadius="lg"
									display="flex"
									justifyContent="space-between"
									alignItems={{ xs: "flex-start", sm: "center" }}
									flexDirection={{ xs: "column", sm: "row" }}
									my={3}
									py={1}
									pl={{ xs: 1, sm: 2 }}
									pr={1}
									mt={0}
								>
									<ArgonTypography variant="button" fontWeight="medium" color="text">
										Url
									</ArgonTypography>
									<ArgonBox width={{ xs: "100%", sm: "50%", md: "50%" }} mt={{ xs: 1, sm: 0 }}>
										<ArgonInput
											type="url"
											size="medium"
											placeholder="https://<YOUR_HOST>/"
											value={url}
											onChange={(e) => {handleChangeValue(e.target.value!=="" ? e.target.value : "","url")}}/>
									</ArgonBox>
								</ArgonBox>
								<ArgonBox
									bgColor={"grey-100"}
									borderRadius="lg"
									display="flex"
									justifyContent="space-between"
									alignItems={{ xs: "flex-start", sm: "center" }}
									flexDirection={{ xs: "column", sm: "row" }}
									my={3}
									py={1}
									pl={{ xs: 1, sm: 2 }}
									pr={1}
									mt={0}
								>
									<ArgonTypography variant="button" fontWeight="medium" color="text">
										email
									</ArgonTypography>
									<ArgonBox width={{ xs: "100%", sm: "50%", md: "50%" }} mt={{ xs: 1, sm: 0 }}>
										<ArgonInput
											type="email"
											size="medium"
											placeholder="aLhXH@example.com"
											value={email}
											onChange={(e) => {handleChangeValue(e.target.value!=="" ? e.target.value : "","email")}}/>
									</ArgonBox>
								</ArgonBox>
								<ArgonBox
									bgColor={"grey-100"}
									borderRadius="lg"
									display="flex"
									justifyContent="space-between"
									alignItems={{ xs: "flex-start", sm: "center" }}
									flexDirection={{ xs: "column", sm: "row" }}
									my={3}
									py={1}
									pl={{ xs: 1, sm: 2 }}
									pr={1}
								>
									<ArgonTypography variant="button" fontWeight="medium" color="text">
										Token
										<NoMaxWidthTooltip title="https://confluence.atlassian.com/enterprise/using-personal-access-tokens-1026032365.html" placement="top">
											<Icon>
												<Info></Info>
											</Icon>
										</NoMaxWidthTooltip >
									</ArgonTypography>
									<ArgonBox width={{ xs: "100%", sm: "50%", md: "50%" }} mt={{ xs: 1, sm: 0 }}>
										<ArgonInput
											size="medium"
											type={showPassword ? 'text' : 'password'}
											placeholder="Add your Token"
											autoComplete="off"
											value={token}
											endAdornment={
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleClickShowPassword}
														onMouseDown={handleMouseDownPassword}
														edge="end"
													>
														{showPassword ? <VisibilityOff /> : <Visibility />}
													</IconButton>
												</InputAdornment>
											}
											onChange={(e) => {handleChangeValue(e.target.value!=="" ? e.target.value : "","token")}}/>
									</ArgonBox>
								</ArgonBox>
								{!check ?
									<ArgonButton fullWidth variant="gradient" color="primary" sx={{ mr: 2 }} size="medium"
												 onClick={handleConnectIntegration} disabled={url==="" || email==="" ||token===""}>
										Connect
									</ArgonButton>
									:
									<LoadingButton fullWidth color="primary" loading variant="contained"  sx={{ mr: 2 }}  size="medium">Connect</LoadingButton>
								}
								{checkError &&
									<>
										{checkSuccess ?

											<></>
											:
											<ArgonTypography variant="button" fontWeight="light"  sx={{ mr: 2, color: 'red' }} color="text">
												<ErrorIcon fontSize="inherit"/> Connection Refused!
											</ArgonTypography>
										}
									</>
								}
							</CardContent>
					}
			</Collapse>
		</Card>
	);
} 


