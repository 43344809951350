/**
 =========================================================
 * Argon Dashboard 2 PRO MUI - v3.0.1
 =========================================================

 * Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

const controllerCardIcons = {

    websiteIcon:(
        <i style={{ color: 'lightslategrey'}}>
            <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                width={60}
            >
                <path d="M16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2m-5.15 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 01-4.33 3.56M14.34 14H9.66c-.1-.66-.16-1.32-.16-2 0-.68.06-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2M12 19.96c-.83-1.2-1.5-2.53-1.91-3.96h3.82c-.41 1.43-1.08 2.76-1.91 3.96M8 8H5.08A7.923 7.923 0 019.4 4.44C8.8 5.55 8.35 6.75 8 8m-2.92 8H8c.35 1.25.8 2.45 1.4 3.56A8.008 8.008 0 015.08 16m-.82-2C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2M12 4.03c.83 1.2 1.5 2.54 1.91 3.97h-3.82c.41-1.43 1.08-2.77 1.91-3.97M18.92 8h-2.95a15.65 15.65 0 00-1.38-3.56c1.84.63 3.37 1.9 4.33 3.56M12 2C6.47 2 2 6.5 2 12a10 10 0 0010 10 10 10 0 0010-10A10 10 0 0012 2z" />
            </svg>
        </i>
    ),
    iosPhoneIcon: (
        <i style={{ color: 'lightslategrey'}}>
            <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                viewBox="0 0 24 24"
                width={60}
            >
                <path stroke="none" d="M0 0h24v24H0z" />
                <path d="M8 3 H16 A2 2 0 0 1 18 5 V19 A2 2 0 0 1 16 21 H8 A2 2 0 0 1 6 19 V5 A2 2 0 0 1 8 3 z" />
                <path d="M11 4h2M12 17v.01" />
            </svg>
        </i>
    ),
    androidPhoneIcon: (
        <i style={{ color: 'lightslategrey'}}>
            <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                width={60}
            >
                <path d="M12.71 16.29l-.15-.12a.76.76 0 00-.18-.09L12.2 16a1 1 0 00-.91.27 1.15 1.15 0 00-.21.33 1 1 0 001.3 1.31 1.46 1.46 0 00.33-.22 1 1 0 00.21-1.09 1 1 0 00-.21-.31zM16 2H8a3 3 0 00-3 3v14a3 3 0 003 3h8a3 3 0 003-3V5a3 3 0 00-3-3zm1 17a1 1 0 01-1 1H8a1 1 0 01-1-1V5a1 1 0 011-1h8a1 1 0 011 1z" />
            </svg>
        </i>
    ),
    saucelabsIcon: (
        <i style={{ color: 'lightslategrey'}}>
            <svg
                viewBox="0 0 24 24"
                fill="currentColor"
                width={60}
            >
                <path d="M2.93 15.475l6.396.004-3.582 6.758A12.005 12.005 0 010 12C0 5.383 5.383 0 12 0c1.761 0 3.436.381 4.94 1.064L5.428 12.58h10.25l-7.539 7.614 3.115-5.876H3.795C3.455 13.164 3.477 12 3.477 12a8.533 8.533 0 018.824-8.518l1.094-1.099a9.728 9.728 0 00-1.375-.098c-5.356 0-9.73 4.357-9.73 9.715-.001 0-.061 1.719.64 3.475zM18.255 1.764A12.007 12.007 0 0124 12c0 6.616-5.384 12-12 12a11.896 11.896 0 01-4.941-1.065l11.426-11.513H8.205l7.656-7.616-3.115 5.876h7.46c.319 1.079.319 2.318.319 2.318a8.535 8.535 0 01-8.861 8.516l-1.084 1.096c5.314.777 10.256-2.901 11.032-8.218a9.71 9.71 0 00.103-1.395c0-1.189-.22-2.369-.647-3.479h-6.394l3.581-6.756z" />
            </svg>
        </i>
    ),
    APIIcon: (
        <i style={{ color: 'lightslategrey'}}>
            <svg
                viewBox="0 0 1024 1024"
                fill="currentColor"
                width={60}
            >
                <path d="M917.7 148.8l-42.4-42.4c-1.6-1.6-3.6-2.3-5.7-2.3s-4.1.8-5.7 2.3l-76.1 76.1a199.27 199.27 0 00-112.1-34.3c-51.2 0-102.4 19.5-141.5 58.6L432.3 308.7a8.03 8.03 0 000 11.3L704 591.7c1.6 1.6 3.6 2.3 5.7 2.3 2 0 4.1-.8 5.7-2.3l101.9-101.9c68.9-69 77-175.7 24.3-253.5l76.1-76.1c3.1-3.2 3.1-8.3 0-11.4zM769.1 441.7l-59.4 59.4-186.8-186.8 59.4-59.4c24.9-24.9 58.1-38.7 93.4-38.7 35.3 0 68.4 13.7 93.4 38.7 24.9 24.9 38.7 58.1 38.7 93.4 0 35.3-13.8 68.4-38.7 93.4zm-190.2 105a8.03 8.03 0 00-11.3 0L501 613.3 410.7 523l66.7-66.7c3.1-3.1 3.1-8.2 0-11.3L441 408.6a8.03 8.03 0 00-11.3 0L363 475.3l-43-43a7.85 7.85 0 00-5.7-2.3c-2 0-4.1.8-5.7 2.3L206.8 534.2c-68.9 69-77 175.7-24.3 253.5l-76.1 76.1a8.03 8.03 0 000 11.3l42.4 42.4c1.6 1.6 3.6 2.3 5.7 2.3s4.1-.8 5.7-2.3l76.1-76.1c33.7 22.9 72.9 34.3 112.1 34.3 51.2 0 102.4-19.5 141.5-58.6l101.9-101.9c3.1-3.1 3.1-8.2 0-11.3l-43-43 66.7-66.7c3.1-3.1 3.1-8.2 0-11.3l-36.6-36.2zM441.7 769.1a131.32 131.32 0 01-93.4 38.7c-35.3 0-68.4-13.7-93.4-38.7a131.32 131.32 0 01-38.7-93.4c0-35.3 13.7-68.4 38.7-93.4l59.4-59.4 186.8 186.8-59.4 59.4z" />
            </svg>
        </i>
    )
};

export default controllerCardIcons;
