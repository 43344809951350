import {Grid, Card, Modal, Backdrop} from "@mui/material";
import ArgonBox from "../../../components/ArgonBox";
import ArgonTypography from "../../../components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import React from "react";
import Table from "../../../components/Table";
import Delete from "@mui/icons-material/Delete";

export function DeleteModal({openModalDeleteTest, setOpenModalDeleteTest, tableTests, actionDeleteTests }) {

	const loadingTable = () => {
		let dataTable = [];
		for (let i in tableTests.getSelectedRowModel().rows) {
			dataTable.push({
				test_name: tableTests.getSelectedRowModel().rows[i].original.feature_name,
			});
		}

		return (<Table
			columns={[
				{ name: "test_name", align: "center" },
			]}
			rows={dataTable}
		/>)
	};

	const handleCloseModal = () => setOpenModalDeleteTest(false);

	const handleOpenModal = () => {
		setOpenModalDeleteTest(true);
	}

	let body = loadingTable();

	return (
		<>
			<ArgonButton startIcon={<Delete />}
						 variant="gradient" color="info"
						 disabled={!tableTests.getSelectedRowModel().rows.length > 0}
						 onClick={handleOpenModal}>
				Delete
			</ArgonButton>
			<Modal open={openModalDeleteTest}
				onClose={(event, reason) => {
				   if (reason !== 'backdropClick') {
					   handleCloseModal(event, reason);
				   }
				}}
				closeAfterTransition
				slots={{ backdrop: Backdrop }}
				slotProps={{
					backdrop: {
						timeout: 500,
					},
				}}
				aria-labelledby="delete-tests"
				aria-describedby="delete-tests">
				<ArgonBox mt={20} mb={20} p={1} xs={3} ml={"auto"} mr={"auto"}>
					<Grid container justifyContent="center">
						<Grid item xs={10} sm={10} lg={5} borderRadius={4}>
							<Card>
								<ArgonBox p={5}>
									<ArgonTypography variant="h3" textAlign={"center"}>
										Delete Test
									</ArgonTypography>
									<ArgonTypography textAlign={"center"} sx={{ pt: 1 }}>
										Are you sure?
									</ArgonTypography>
									<ArgonBox mt={1}>
										<Grid container justifyContent="center">
											<Grid item sx={{ maxHeight: '20em', overflow: "auto"}}>
												{body}
											</Grid>
										</Grid>
									</ArgonBox>
									<ArgonBox mt={4}>
										<Grid container spacing={1} justifyContent="center">
											<Grid item>
												<ArgonButton color="dark" variant="gradient" onClick={handleCloseModal}>Cancel</ArgonButton>
											</Grid>
											<Grid item>
												<ArgonButton color="error" variant="gradient" onClick={() => actionDeleteTests(tableTests.getSelectedRowModel().rows)}>Yes, Delete!</ArgonButton>
											</Grid>
										</Grid>
									</ArgonBox>
								</ArgonBox>
							</Card>
						</Grid>
					</Grid>
				</ArgonBox>
			</Modal>
		</>);
}