import React, {useEffect, useState} from "react";

// @mui material components
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Backdrop from '@mui/material/Backdrop';
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

// Argon Dashboard 2 PRO MUI components
import ArgonButton from "../../../components/ArgonButton";
import ArgonBox from "../../../components/ArgonBox";

//import custom components

import {enqueueSnackbar} from "notistack";
import FolderTree from "../../url-website/components/FolderTree";
import OtherInformation from "./OtherInformation";
import GenerateSourceCodeFile from "./GenerateSourceCodeFile";
import {addTest} from "../../../api/BackendApi/TestRepository";
import {
    downloadFile,
    generate,
} from "../../../api/BackendApi/microservices/GenerativeTest";
import { pushCloudRepository } from "../../../api/BackendApi/microservices/ManagementTool";
import ArgonTypography from "../../../components/ArgonTypography";
import LinearProgress from "@mui/material/LinearProgress";
import OtherInformationLite from "./OtherInformationLite";
import {AutoFixHigh} from "@mui/icons-material";

function CreateNewTestCaseAdvanced({user, isTestBook, buttonDisabled, table, runId, setRunId, runType, setRunType, setDownloadScriptsGenerated, saveInTestRepository, handleSaveTest}) {

    const [openModalCreateNewTestCase, setOpenModalCreateNewTestCase] = useState(false);
    const [openModalLoading, setOpenModalLoading] = useState(false);

    const handleCloseModal = () => {
        setOpenModalCreateNewTestCase(false);
    }

    const handleCloseModalLoading = () => {
        setOpenModalLoading(false);
    }

    const handleOpenModal = () => {
        setActiveStep(0)
        setActiveStepLite(0)
        setSendAddNewTests(false)
        setOpenModalCreateNewTestCase(true);
    }

    const handleOpenModalLoading = () => {
        setOpenModalLoading(true);
    }

    const steps = ["Test Repository", "Generate Source Code File", "Others Information"];
    const [activeStep, setActiveStep] = useState(0);
    const isLastStep = activeStep === steps.length - 1;
    const handleNext = () => setActiveStep(activeStep + 1);
    const handleBack = () => setActiveStep(activeStep - 1);

    const steps_lite = ["Generate Source Code File", "Others Information"];
    const [activeStepLite, setActiveStepLite] = useState(0);
    const isLastStepLite = activeStepLite === steps_lite.length - 1;
    const handleNextLite = () => setActiveStepLite(activeStepLite + 1);
    const handleBackLite = () => setActiveStepLite(activeStepLite - 1);


    const [selectedFolderId, setSelectedFolderId] = useState(null);

    const [selectedCapability, setSelectedCapability] = useState(null);
    const [selectedTypeTest, setSelectedTypeTest] = useState(null);
    const [checkedFeatureFile, setCheckedFeatureFile] = useState(true);
    const [checkedGenerateSourceCode, setCheckedGenerateSourceCode] = useState(false);

    const [binaryTest, setBinaryTest] = useState(null);
    const [commandTest, setCommandTest] = useState(null);
    const [otherArgsTest, setOtherArgsTest] = useState(null);
    const [cloudType, setCloudTypeSelected] = useState(null);
    const [urlRepository, setUrlRepository] = useState(null);
    const [tokenCloudRepository, setTokenCloudRepository] = React.useState(null);
    const [orgCloudRepository, setOrgCloudRepository] = React.useState(null);
    const [projectCloudRepository, setProjectCloudRepository] = React.useState(null);
    const [selectedRepository, setSelectedRepository] = useState(null);
    const [selectedBranchRepository,setSelectedBranchRepository] = useState(null);
    const [checkedPushInBranch, setCheckedPushInBranch] = useState(true);

    const [sendAddNewTests, setSendAddNewTests] = useState(false);

    let runScriptId = 0;
    let runScriptType = "";

    const handleSendAddNewTests = () => {
        setSendAddNewTests(true)
        console.log("folder: " + selectedFolderId)
        console.log("test type: " + selectedTypeTest)
        console.log("capability: " + selectedCapability)

        console.log("check feature: " + checkedFeatureFile)
        console.log("check source code: " + checkedGenerateSourceCode)

        console.log("binaryTest: " + binaryTest)
        console.log("command: " + commandTest)
        console.log("args: " + otherArgsTest)

        console.log("cloud type: " + cloudType)
        console.log("url: " + urlRepository)
        console.log("token: " + tokenCloudRepository)
        console.log("org: " + orgCloudRepository)
        console.log("project: " + projectCloudRepository)
        console.log("repository: " + selectedRepository)
        console.log("branch: " + selectedBranchRepository)
        console.log("push in branch: " + checkedPushInBranch)

        let push = false;
        let gherkin = false;
        let sourceCode = false;

        handleCloseModal();
        handleOpenModalLoading();

        //Create Test Instance in Test Repository
        if(selectedFolderId !== null){
            enqueueSnackbar('Starting Test creation in Test Repository!',{variant:'warning'});
            createTestInTestRepository()
                .then(r => enqueueSnackbar('Test created in Test Repository!',{variant:'success'}))
                .catch(e => {
                    enqueueSnackbar('Error creating Test in Test Repository!',{variant:'error'})
                    console.log(e)
                });
        }

        //Check if I generate gherkin
        if(checkedFeatureFile){
            gherkin = true;
            enqueueSnackbar('Starting Gherkin Test creation!',{variant:'warning'});
            generateGherkin().then(r => {
                if(r.status === 200){
                    enqueueSnackbar('Gherkin Test created!',{variant:'success'})
                    //Check if I generate source code
                    if(checkedGenerateSourceCode){
                        sourceCode = true;
                        enqueueSnackbar('Starting Source Code creation!',{variant:'warning'});
                        generateSourceCode().then(r => {
                            if(r.status === 200) {
                                enqueueSnackbar('Source Code created!',{variant:'success'})
                                //Check if I push in branch
                                if (checkedPushInBranch) {
                                    push = true;
                                    enqueueSnackbar('Starting Push in Cloud Repository!', {variant: 'warning'});
                                    setDownloadScriptsGenerated(true);
                                    pushInCloudRepository().then(r => {
                                        enqueueSnackbar('All files pushed!',{variant:'success'})
                                        handleCloseModalLoading();
                                    }).catch(e => {
                                        enqueueSnackbar('Error pushing files!',{variant:'error'})
                                        console.log(e)
                                    }).finally(() => {
                                        handleCloseModalLoading();
                                    });
                                } else {
                                    setDownloadScriptsGenerated(true);
                                    handleCloseModalLoading();
                                }
                            }else{
                                enqueueSnackbar('Source code not generated!',{variant:'error'})
                                throw new Error("Source code not generated")
                            }
                        }).catch(e => {
                            enqueueSnackbar('Error generating Source Code!',{variant:'error'})
                            console.log(e)
                        }).finally(() => {
                            handleCloseModalLoading();
                        });
                    }else{
                        handleCloseModalLoading();
                    }
                }else{
                    enqueueSnackbar('Error generating Gherkin file!',{variant:'error'})
                    handleCloseModalLoading();
                }
            }).catch(e => {
                console.log(e)
                enqueueSnackbar('Error generating Gherkin file!',{variant:'error'})
            }).finally(() => {
                handleCloseModalLoading();
            });
        }

        //Check if I generate source code
        if(checkedGenerateSourceCode && !sourceCode && !gherkin){
            sourceCode = true;
            enqueueSnackbar('Starting Source Code creation!',{variant:'warning'});
            generateSourceCode().then(r => {
                if(r.status === 200){
                    enqueueSnackbar('Source Code created!',{variant:'success'})
                    if(checkedPushInBranch){
                        push = true;
                        enqueueSnackbar('Starting Push in Cloud Repository!', {variant: 'warning'});
                        setDownloadScriptsGenerated(true);
                        pushInCloudRepository()
                            .then(r => enqueueSnackbar('All files pushed!',{variant:'success'}))
                            .catch(e => {
                                enqueueSnackbar('Error pushing files!',{variant:'error'})
                                console.log(e)
                            })
                            .finally(() => {
                                handleCloseModalLoading();
                            });
                    }else{
                        setDownloadScriptsGenerated(true);
                        handleCloseModalLoading();
                    }
                }else{
                    throw new Error("Source code not generated")
                }
            }).catch(e => {
                enqueueSnackbar('Error generating Source Code!',{variant:'error'})
                console.log(e)
            }).finally(() => {
                handleCloseModalLoading();
            });
        }else {
            if(checkedPushInBranch && runScriptId !== 0 && runId !== 0){
                push = true;
                enqueueSnackbar('Start Push in Cloud Repository!',{variant:'warning'});
                pushInCloudRepository()
                    .then(r => enqueueSnackbar('All files pushed!',{variant:'success'}))
                    .catch(e => {
                        enqueueSnackbar('Error pushing files!',{variant:'error'})
                        console.log(e)
                    })
                    .finally(() => {
                    handleCloseModalLoading();
                });
            }
        }

        if(checkedPushInBranch && !sourceCode && !gherkin){
            enqueueSnackbar('Start Push in Cloud Repository!',{variant:'warning'});
            pushInCloudRepository()
                .then(r => enqueueSnackbar('All files pushed!',{variant:'success'}))
                .catch(e => {
                    enqueueSnackbar('Error pushing files!',{variant:'error'})
                    console.log(e)
                })
                .finally(() => {
                    handleCloseModalLoading();
                });
        }
    }

    const createTestInTestRepository = async() => {

        for(let test of table.getSelectedRowModel().rows){

            let name = "";

            if(test.original.url)
                name = test.original.url;
            else if (test.original.name)
                name = test.original.name;
            else if (test.original.feature_name)
                name = test.original.feature_name;
            else if (test.original.test_name)
                name = test.original.test_name;

            let args = "";
            switch(selectedTypeTest){
                case "functional":
                    if(selectedCapability === "Web"){
                        args = "${driver}" + " " + name + " " + otherArgsTest
                    }else if(selectedCapability === "Android"){
                        args = "${device}" + " " + name + " " + otherArgsTest
                    } else{
                        args = "<Other_To_Add>" + " " + name + " " + otherArgsTest
                    }
                    break;
            }

            await addTest(selectedFolderId, {
                "name": name,
                "notes": "",
                "description": "",
                "precondition": "",
                "binaries": binaryTest,
                "command": commandTest,
                "arguments": args
            }).catch((error)  => {
                throw new Error(error)
            })
        }
    }

    const pushInCloudRepository = async () => {
        let idsTests = table.getSelectedRowModel().rows.map((row) => {
            return row.id;
        })

        let pushDetails = []

        if(runId !== null){
            pushDetails.push({"run_id":runId,"step_name":runType})
        }

        if(runScriptId !== 0){
            pushDetails.push({"run_id":runScriptId,"step_name":runScriptType})
        }

        let infoToPush = {
            "push": pushDetails,
            "features_id": idsTests,
            "cloudType": cloudType,
            "url": urlRepository,
            "token": tokenCloudRepository,
            "org": orgCloudRepository,
            "project": projectCloudRepository,
            "repository": selectedRepository,
            "branch": selectedBranchRepository
        }

        let response = await pushCloudRepository(infoToPush)

        try{
            if(response.status === 200){
                enqueueSnackbar('All files selected are pushed!',{variant:'success'})
            }else {
                throw Error("Error during push!")
            }
        }catch (error) {
            enqueueSnackbar('Error during push!',{variant:'error'})
        }finally {
            return response
        }
    }

    const generateSourceCode = async () => {
        let idsTests = table.getSelectedRowModel().rows.map((row) => {
            return row.id;
        })

        let response = await generate(idsTests,runId,"scripts")

        try{
            if(response.status === 200){
                enqueueSnackbar('Source code generated!',{variant:'success'})
                runScriptId =response.data.run_id
                runScriptType = response.data.step_name
            }else{
                throw Error("Error generating source code!")
            }

        }catch (error) {
            enqueueSnackbar('Error generating source code!',{variant:'error'})
        }finally {
            return response
        }
    }

    const generateGherkin = async () => {

        let idsTests = table.getSelectedRowModel().rows.map((row) => {
            return row.id;
        })

        let response = await generate(idsTests,runId,"gherkintest")

        try{
            if(response.status === 200){
                enqueueSnackbar('Gherkin File generated!', {variant: 'success'})

                const data = response.data.functional_gherkintest.map((item) => {
                    return {
                        id: item.feature_id,
                        feature_name: item.feature_name,
                        scenarios: item.scenarios
                    };
                })

                setRunId(response.data.run_id)
                setRunType(response.data.step_name)

                handleSaveTest(data)

            }else{
                throw Error("Error generating Gherkin file!")
            }
        }catch (error) {
            enqueueSnackbar('Error generating Gherkin file!',{variant:'error'})
        }finally {
            return response
        }
    }

    const getStepContent = (stepIndex) => {

        if(saveInTestRepository){

            switch (stepIndex) {
                case 0:
                    return <FolderTree onSelect={setSelectedFolderId}/>;
                case 1:
                    return <GenerateSourceCodeFile isTestBook={isTestBook} checkedGenerateSourceCode={checkedGenerateSourceCode}  onSelect={{setSelectedCapability,setSelectedTypeTest,setCheckedFeatureFile,setCheckedGenerateSourceCode}}/>;
                case 2:
                    return <OtherInformation
                        user={user}
                        table={table}
                        selectedTypeTest={selectedTypeTest}
                        binaryTest={binaryTest}
                        commandTest={commandTest}
                        tokenCloudRepository={tokenCloudRepository}
                        urlRepository={urlRepository}
                        orgCloudRepository={orgCloudRepository}
                        projectCloudRepository={projectCloudRepository}
                        checkedPushInBranch={checkedPushInBranch}
                        onSelect={{setBinaryTest,setCommandTest,setOtherArgsTest,setCloudTypeSelected, setUrlRepository,setTokenCloudRepository,setOrgCloudRepository,setProjectCloudRepository,setSelectedRepository,setSelectedBranchRepository,setCheckedPushInBranch}}/>;
                default:
                    return null;
            }
        }else{
            switch (stepIndex) {
                case 0:
                    return <GenerateSourceCodeFile isTestBook={isTestBook} checkedGenerateSourceCode={checkedGenerateSourceCode}  onSelect={{setSelectedCapability,setSelectedTypeTest,setCheckedFeatureFile,setCheckedGenerateSourceCode}}/>;
                case 1:
                    return <OtherInformationLite
                        user={user}
                        table={table}
                        checkedGenerateSourceCode={checkedGenerateSourceCode}
                        tokenCloudRepository={tokenCloudRepository}
                        urlRepository={urlRepository}
                        orgCloudRepository={orgCloudRepository}
                        projectCloudRepository={projectCloudRepository}
                        checkedPushInBranch={checkedPushInBranch}
                        onSelect={{setCloudTypeSelected,setUrlRepository,setTokenCloudRepository,setOrgCloudRepository,setProjectCloudRepository,setSelectedRepository,setSelectedBranchRepository,setCheckedPushInBranch}}/>;
                default:
                    return null;
            }
        }
    }

    return (
      <div>
          <ArgonButton variant="gradient" color="info" disabled={buttonDisabled} onClick={handleOpenModal}>
              <PlaylistAddIcon/>&nbsp;
              Generate New Test
          </ArgonButton>
          <Modal open={openModalCreateNewTestCase}
                 onClose={(event, reason) => {
                     if (reason !== 'backdropClick') {
                         handleCloseModal(event, reason);
                     }
                 }}
                 closeAfterTransition
                 slots={{ backdrop: Backdrop }}
                 slotProps={{
                     backdrop: {
                         timeout: 500,
                     },
                 }}
                 aria-labelledby="update-urls"
                 aria-describedby="update-urls" >
              <ArgonBox mt={20} mb={20} p={1} xs={3} ml={"auto"} mr={"auto"}>
                  <Grid container justifyContent="center">
                      <Grid item xs={10} sm={10} lg={8} borderRadius={4}>
                          <Card>
                              <ArgonBox p={2}>
                                  {saveInTestRepository ?
                                      <Stepper activeStep={activeStep} alternativeLabel sx={{marginTop: "1em", marginBottom: "1.5em"}}>
                                          {steps.map((label) => (
                                              <Step key={label}>
                                                  <StepLabel>{label}</StepLabel>
                                              </Step>
                                          ))}
                                      </Stepper>
                                      :
                                      <Stepper activeStep={activeStepLite} alternativeLabel>
                                          {steps_lite.map((label) => (
                                              <Step key={label}>
                                                  <StepLabel>{label}</StepLabel>
                                              </Step>
                                          ))}
                                      </Stepper>
                                  }
                                  <ArgonBox>
                                      {saveInTestRepository ?
                                          getStepContent(activeStep)
                                          :
                                          getStepContent(activeStepLite)
                                      }
                                      {saveInTestRepository ?
                                          <ArgonBox mt={5} mb={2} width="100%" display="flex" justifyContent="space-between">
                                              <ArgonBox width="100%" display="flex">
                                                  <ArgonButton variant="gradient" color="dark" sx={{ marginRight: "1em" }} onClick={handleCloseModal}>
                                                      Close
                                                  </ArgonButton>
                                                  {activeStep === 0 ? (
                                                      <ArgonBox />
                                                  ) : (
                                                      <ArgonButton variant="gradient" color="light" onClick={handleBack}>
                                                          Back
                                                      </ArgonButton>
                                                  )}
                                              </ArgonBox>
                                              {activeStep === 0 && (
                                              <ArgonButton
                                                  variant="gradient"
                                                  color="light"
                                                  disabled={selectedFolderId === null}
                                                  onClick={!isLastStep ? handleNext : undefined}
                                              >
                                                  Next
                                              </ArgonButton>)}
                                              {activeStep === 1 && (
                                                  <ArgonButton
                                                      variant="gradient"
                                                      color="light"
                                                      disabled={selectedTypeTest === null || selectedCapability === null}
                                                      onClick={!isLastStep ? handleNext : undefined}
                                                  >
                                                      Next
                                                  </ArgonButton>)}
                                              {activeStep === 2 && (
                                                  <ArgonButton
                                                      color="info"
                                                      onClick={handleSendAddNewTests}
                                                      loading={sendAddNewTests}
                                                      loadingPosition="start"
                                                      startIcon={<AutoFixHigh />}
                                                      variant="gradient"
                                                  >
                                                      <span>Generate</span>
                                                  </ArgonButton>
                                                  )}
                                          </ArgonBox>
                                          :
                                          <ArgonBox mt={3} width="100%" display="flex" justifyContent="space-between">
                                              <ArgonBox width="100%" display="flex">
                                                  <ArgonButton variant="gradient" color="dark" sx={{ marginRight: "1em" }} onClick={handleCloseModal}>
                                                      Close
                                                  </ArgonButton>
                                                  {activeStepLite === 0 ? (
                                                      <ArgonBox />
                                                  ) : (
                                                      <ArgonButton variant="gradient" color="light" onClick={handleBackLite}>
                                                          Back
                                                      </ArgonButton>
                                                  )}
                                              </ArgonBox>
                                              {activeStepLite === 0 && (
                                                  <ArgonButton
                                                      variant="gradient"
                                                      color="light"
                                                      disabled={selectedTypeTest === null || selectedCapability === null}
                                                      onClick={!isLastStepLite ? handleNextLite : undefined}
                                                  >
                                                      Next
                                                  </ArgonButton>)}
                                              {activeStepLite === 1 && (
                                                  <ArgonButton
                                                      color="success"
                                                      onClick={handleSendAddNewTests}
                                                      loading={sendAddNewTests}
                                                      loadingPosition="start"
                                                      startIcon={<AutoFixHigh />}
                                                      variant="gradient"
                                                  >
                                                      <span>Generate</span>
                                                  </ArgonButton>)}
                                          </ArgonBox>
                                      }
                                  </ArgonBox>
                              </ArgonBox>
                          </Card>
                      </Grid>
                  </Grid>
              </ArgonBox>
          </Modal>
          <Modal open={openModalLoading}
                 onClose={(event, reason) => {
                     if (reason !== 'backdropClick') {
                         handleCloseModalLoading(event, reason);
                     }
                 }}
                 closeAfterTransition
                 slots={{ backdrop: Backdrop }}
                 slotProps={{
                     backdrop: {
                         timeout: 500,
                     },
                 }}
                 aria-labelledby="update-urls"
                 aria-describedby="update-urls" >
              <ArgonBox mt={40} mb={20} p={1} xs={3} ml={"auto"} mr={"auto"}>
                  <Grid container justifyContent="center">
                      <Grid item xs={10} sm={10} lg={8} borderRadius={4}>
                          <Card>
                              <ArgonBox sx={{ width: '100%' }} mt={5} mb={2}>
                                  <LinearProgress />
                              </ArgonBox>
                              <ArgonBox mb={5}>
                                  <ArgonTypography variant="h3" textAlign={"center"}>
                                      Loading...
                                  </ArgonTypography>
                              </ArgonBox>
                          </Card>
                      </Grid>
                  </Grid>
              </ArgonBox>
          </Modal>
      </div>
    );
}

export default CreateNewTestCaseAdvanced;
