// @mui material components
import Grid from "@mui/material/Grid";
import { CircularProgress } from "@mui/material";

// components
import ControllerCard from "../../../components/Cards/ControllerCard";
import controllerCardIcons from "./controllerCardIcons";
import ArgonBox from "../../../components/ArgonBox";

function BoxCapabilities({ capabilities, flagsMap, onChange }) {

  const {
    websiteIcon,
    iosPhoneIcon,
    androidPhoneIcon,
    saucelabsIcon,
    APIIcon
  } = controllerCardIcons;

  const mapCapabilityToIcon = (name) => {
    return {
      "Web": websiteIcon,
      "Android": androidPhoneIcon,
      "iOS": iosPhoneIcon,
      "SAUCELABS": saucelabsIcon,
      "API": APIIcon
    }[name] ?? websiteIcon;
  };

  if (capabilities == null) {
    return <CircularProgress />;
  }

  return (
    <ArgonBox mt={2} mb={3}>
      <Grid container spacing={3} mb={3}>
        {capabilities.map(
          (capability) => {
            return <Grid item key={capability.name} xs={12} md={6} lg={3}>
              <ControllerCard
                icon={mapCapabilityToIcon(capability.name)}
                title={capability.name}
                description={capability.notes ?? ""}
                color={"dark"}
                state={flagsMap[capability.name] ?? false}
                onChange={() => onChange(capability.name)}
              />
            </Grid>;
          }
        )}
      </Grid>
    </ArgonBox>
  );
}

export default BoxCapabilities;
