import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from "@mui/material/Grid";

import AddIcon from '@mui/icons-material/Add';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import FolderIcon from '@mui/icons-material/Folder';
import SyncIcon from '@mui/icons-material/Sync';
import DeleteIcon from '@mui/icons-material/Delete';

import { TreeItem, TreeView } from "@mui/x-tree-view";
import { CircularProgress, tooltipClasses } from '@mui/material';
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import ArgonButton from "components/ArgonButton";
import ArgonInput from "components/ArgonInput";

import ArgonBox from "components/ArgonBox";
import AddNewCycleFolderView from "pages/test-execution/components/CycleTree/components/AddNewCycleFolderView";
import AddNewCycleView from "pages/test-execution/components/CycleTree/components/AddNewCycleView";

import { TestCycles } from "api/BackendApi/TestCycles";
import ArgonTypography from "components/ArgonTypography";
import CropFreeIcon from '@mui/icons-material/CropFree';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import EditIcon from "@mui/icons-material/Edit";
import { enqueueSnackbar } from "notistack";

import Tooltip from "@mui/material/Tooltip";
import RenameCycle from "./components/RenameCycle";
import { ListAlt } from "@mui/icons-material";

import { styled } from "@mui/material/styles";

function CycleTree({ onSelect, setTestInstanceShowed, ...rest }) {

  const buttonStyleLeft = ({ functions: { pxToRem } }) => ({
    width: pxToRem(34),
    minWidth: pxToRem(34),
    height: pxToRem(34),
    minHeight: pxToRem(34),
    mr: 1
  });

  const NoMaxWidthTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

  // State definition
  const [treeTextFilter, setTreeTextFilter] = useState("");
  const [addCycleDialogOpen, setAddCycleDialogOpen] = useState(false);
  const [renameCycleDialogOpen, setRenameCycleDialogOpen] = useState(false);
  const [addCycleFolderDialogOpen, setAddCycleFolderDialogOpen] = useState(false);

  const [selectedEntityId, _setSelectedEntityId] = useState(sessionStorage.getItem("selentityid"));
  const [selectedFolderId, _setSelectedFolderId] = useState(sessionStorage.getItem("selfldid"));
  const [selectedNodeName, _setSelectedNodeName] = useState(sessionStorage.getItem("selnodename"));
  const [selectedTreeNode, _setSelectedTreeNode] = useState(sessionStorage.getItem("seltreenode"));
  const [expandedTreeFolders, _setExpandedTreeFolders] = useState(JSON.parse(sessionStorage.getItem("treecache") ?? "[]"));
  const [cycles, setCycles] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const setSelectedEntityId = (value) => {
    sessionStorage.setItem("selentityid", value);
    _setSelectedEntityId(value);
  };

  const setSelectedFolderId = (value) => {
    sessionStorage.setItem("selfldid", value);
    _setSelectedFolderId(value);
  };

  const setSelectedNodeName = (value) => {
    sessionStorage.setItem("selnodename", value);
    _setSelectedNodeName(value);
  };

  const setSelectedTreeNode = (value) => {
    sessionStorage.setItem("seltreenode", value);
    _setSelectedTreeNode(value);
  };

  const setExpandedTreeFolders = (value) => {
    sessionStorage.setItem("treecache", JSON.stringify(value));
    _setExpandedTreeFolders(value);
  };

  const onNodeSelect = (e, id) => {
    console.log(id);
    e.stopPropagation();
    setSelectedTreeNode(id);

    if (id.split("-")[1] !== "root") {
      let currentName = cycles.find(c => c._id === id.split("-")[1]);
      setSelectedNodeName(currentName.name);
      setSelectedEntityId(id.split("-")[1]);
    } else {
      setSelectedNodeName("Root");
      setSelectedEntityId("root");
    }

    if (id.startsWith("folder-")) {
      setSelectedFolderId(id.split("-")[1]);
    } else {
      setSelectedFolderId(null);
    }
    onSelect(id);
  };

  const deleteNode = async () => {
    setIsDeleting(true);
    try {
      let id = selectedTreeNode.split('-')[1];
      await TestCycles.deleteEntity(id);
      enqueueSnackbar("Deleted!", { variant: "success" });
    } finally {
      setIsDeleting(false);
      setSelectedTreeNode(null);
      setSelectedFolderId(null);
      setSelectedEntityId(null);
      await refreshTree();
    }
  }

  const cloneCycle = async () => {
    try {
      let id = selectedTreeNode.split('-')[1];
      await TestCycles.cloneCycle(id);
      enqueueSnackbar("Deleted!", { variant: "success" });
    } finally {
      await refreshTree();
    }
  };

  const refreshTree = async () => {
    setCycles(null);
    try {
      let res = await TestCycles.testCycles();
      setCycles(res.data.cycles);
      onSelect(selectedTreeNode);
    } catch (e) {
      console.log(e);
    }
  };

  const handleClose = () => {
    setAddCycleDialogOpen(false);
    setAddCycleFolderDialogOpen(false);
    setRenameCycleDialogOpen(false);
    refreshTree();
  };

  // Function definitions
  const getTreeItemsFromData = (currentId, children = []) => {
    if (children === null) {
      return [];
    }

    let itemChildren = undefined;

    for (let child of children) {
      if (child.parent === currentId) {
        if (itemChildren === undefined) {
          itemChildren = [];
        }
        itemChildren = itemChildren.concat(getTreeItemsFromData(child._id, children)).filter(e => e !== null);
      }
    }

    if (currentId === "root") return itemChildren;

    let current = children.find(c => c._id === currentId);
    if (treeTextFilter.length > 0) {
      if (current.type === "cycle" && !current.name.toLowerCase().includes(treeTextFilter.toLowerCase())) {
        return null;
      }
    }
    let nodeId = `${current.type}-${current._id}`;
    return <TreeItem
      key={current._id}
      nodeId={nodeId}
      collapseIcon={<ExpandMoreIcon onClick={(e) => removeExpandedNodeId(nodeId)} />}
      expandIcon={<ChevronRightIcon onClick={(e) => addExpandedNodeId(nodeId)} />}
      label={
        <div onClick={event => { onNodeSelect(event, `${current.type}-${current._id}`) }}>
          <Grid container spacing="3" alignItems="center" direction="row">
            <Grid key={0} item>
              {current.type === "folder" ? <FolderIcon sx={() => ({ mt: 1 })} /> : <ListAlt sx={() => ({ mt: 1 })} />}
            </Grid>
            <Grid key={1} item>
              <ArgonTypography fontSize={16}>
                {current.name}
              </ArgonTypography>
            </Grid>
          </Grid></div>

      } >{itemChildren}</TreeItem>;
  };

  const addExpandedNodeId = (nodeId) => {
    setExpandedTreeFolders([nodeId, ...expandedTreeFolders]);
  }

  const removeExpandedNodeId = (nodeId) => {
    let index = expandedTreeFolders.indexOf(nodeId);
    while (index > -1) {
      expandedTreeFolders.splice(index, 1);
      index = expandedTreeFolders.indexOf(nodeId);
    }
    setExpandedTreeFolders(expandedTreeFolders);
    // onNodeSelect(event, nodeId)
  }

  useEffect(() => {
    refreshTree();
    onSelect();
  }, []);

  // Loading indicator
  let content = <Stack direction='row' justifyContent='center'>
    <CircularProgress />
    <Box height={10}></Box>
  </Stack>;

  // Loaded content
  if (cycles != null && !isDeleting) {
    console.log(expandedTreeFolders);
    content = (
      <TreeView
        defaultExpanded={expandedTreeFolders}
        selected={[selectedTreeNode]}
        aria-label="file system navigator"
      >
        <TreeItem
          key={"root"}
          nodeId={`folder-root`}
          collapseIcon={<ExpandMoreIcon onClick={(e) => removeExpandedNodeId(`folder-root`)} />}
          expandIcon={<ChevronRightIcon onClick={(e) => addExpandedNodeId(`folder-root`)} />}
          label={
            <div onClick={event => { onNodeSelect(event, `folder-root`) }}>
              <Grid container spacing="3" alignItems="center">
                <Grid key={0} item>
                  <FolderIcon sx={() => ({ mt: 1 })} />
                </Grid>
                <Grid key={1} item>
                  <ArgonTypography fontSize={16} >
                    Root
                  </ArgonTypography>
                </Grid>
              </Grid>
            </div>
          }>
          {getTreeItemsFromData("root", cycles)}
        </TreeItem>
      </TreeView>
    );
  }

  return (

    <Card sx={{ display: "flex", height: "calc(100vh - 20vh)" }}>
      <Dialog
        open={addCycleDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>{"Create Cycle"}</DialogTitle>
        <DialogContent>
          <AddNewCycleView closeDialog={handleClose} folderId={selectedFolderId} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={addCycleFolderDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>{"Create New Folder"}</DialogTitle>
        <DialogContent>
          <AddNewCycleFolderView closeDialog={handleClose} folderId={selectedFolderId} />
        </DialogContent>
      </Dialog>
      <Dialog
        open={renameCycleDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>{"Rename Entity"}</DialogTitle>
        <DialogContent>
          <RenameCycle closeDialog={handleClose} entityId={selectedEntityId} folderName={selectedNodeName} />
        </DialogContent>
      </Dialog>
      <ArgonBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={6} md={12}>
            <Box display="flex" flexDirection="column" alignItems="start">
              <ArgonBox>
                <ArgonButton iconOnly variant="contained" color="info" size="large" sx={buttonStyleLeft} onClick={refreshTree}>
                  <NoMaxWidthTooltip title="Refresh" placement="top">
                    <SyncIcon />
                  </NoMaxWidthTooltip>
                </ArgonButton>
                <ArgonButton iconOnly variant="contained" color="info" size="large" sx={buttonStyleLeft} onClick={refreshTree}>
                  <NoMaxWidthTooltip title="Maximize (TODO)" placement="top">
                    <CropFreeIcon />
                  </NoMaxWidthTooltip>
                </ArgonButton>
                {window.user.canCreateTestExec() && <ArgonButton
                  iconOnly
                  disabled={selectedFolderId == null}
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={buttonStyleLeft}
                  onClick={setAddCycleDialogOpen}>
                  <NoMaxWidthTooltip title="New Test Cycle" placement="top">
                    <AddIcon />
                  </NoMaxWidthTooltip>
                </ArgonButton>}
                {window.user.canCreateTestExec() && <ArgonButton
                  iconOnly
                  disabled={selectedFolderId == null}
                  variant="contained"
                  color="primary"
                  size="large"
                  sx={buttonStyleLeft}
                  onClick={setAddCycleFolderDialogOpen}>
                  <NoMaxWidthTooltip title="New Folder" placement="top">
                    <CreateNewFolderIcon />
                  </NoMaxWidthTooltip>
                </ArgonButton>}
                <ArgonButton
                  iconOnly
                  disabled={selectedTreeNode == null || isDeleting || selectedTreeNode === 'folder-root'}
                  variant="contained"
                  color="error"
                  size="large"
                  sx={buttonStyleLeft}
                  onClick={deleteNode}
                >
                  <NoMaxWidthTooltip title="Delete" placement="top">
                    <DeleteIcon />
                  </NoMaxWidthTooltip>
                </ArgonButton>
                {window.user.canEditTestExec() && <ArgonButton
                  iconOnly
                  variant="contained"
                  color="dark"
                  size="large"
                  sx={buttonStyleLeft}
                  disabled={selectedTreeNode == 'folder-root'}
                  onClick={setRenameCycleDialogOpen}>
                  <NoMaxWidthTooltip title="Rename" placement="top">
                    <EditIcon />
                  </NoMaxWidthTooltip>
                </ArgonButton>}
                {window.user.canEditTestExec() && <ArgonButton
                  iconOnly
                  variant="contained"
                  color="dark"
                  size="large"
                  sx={buttonStyleLeft}
                  disabled={selectedTreeNode == null || isDeleting || selectedTreeNode.startsWith("folder-")}
                  onClick={cloneCycle}>
                  <NoMaxWidthTooltip title="Clone" placement="top">
                    <LibraryAddIcon />
                  </NoMaxWidthTooltip>
                </ArgonButton>}
              </ArgonBox>
            </Box>
          </Grid>
        </Grid>
      </ArgonBox>
      <ArgonBox p={2}>
        <Grid container>
          <Grid item xs={12}>
            <ArgonInput
              type="text"
              placeholder="Search..."
              size="medium"
              onClick={() => { }}
              onChange={(e) => { setTreeTextFilter(e.target.value) }}
            />
          </Grid>
        </Grid>
      </ArgonBox>
      <ArgonBox p={1}>
        {content}
      </ArgonBox>
    </Card>
  );
}

CycleTree.propTypes = {
  onSelect: PropTypes.func,
  setTestInstanceShowed: PropTypes.func,
  showCardButtons: PropTypes.bool
};


export default CycleTree;
