import { Navigate } from "react-router-dom";
import useAuth from "../../utils/useAuth";
import React, { useState, useEffect } from "react";
import ReactEcharts from "echarts-for-react";

// @mui material components
import { styled } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import { CardHeader, CircularProgress, Stack } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Argon Dashboard 2 PRO MUI example components
import ArgonButton from "../../components/ArgonButton";
import ArgonBox from "../../components/ArgonBox";

// Overview page components
import Footer from "../../components/Footer";
import DashboardLayout from "../../components/LayoutContainers/DashboardLayout";
import Header from "../../components/Header";
import SelectFilters from "../dashboard/components/SelectFilters";
import BoxCapabilities from "../dashboard/components/BoxCapabilities";

import { TestConfig } from "../../api/BackendApi/TestConfiguration";
import ArgonTypography from "../../components/ArgonTypography";
import {Module} from "../../api/BackendApi/Module";


const loading = () => {
  return (<Stack
    direction="row"
    justifyContent="center"
    alignItems="center"
    sx={{ width: 1, height: '400px' }}
  >
    <CircularProgress size={64} />
  </Stack>);
};

function PerformancePage() {

  const isLoggedIn = useAuth().ensureLoggedIn();

  let initialDateStart = new Date();
  let initialDateEnd =  new Date('January 01, 2024 00:00:00');

  const [expanded, setExpanded] = useState(true);
  const [moduleId, setModuleId] = useState(null);
  const [configs, setConfigs] = useState(null);

  const [chartsConfigs, setChartsConfigs] = useState(null);

  const [enabledCapabilities, setEnabledCapablities] = useState({});
  const [selectedFilters, setSelectedFilters] = useState({});
  const [dateRange, setDateRange] = useState([initialDateStart, initialDateEnd]);

  const [appliedFilters, setAppliedFilters] = useState(null);
  const [loadedCharts, setLoadedCharts] = useState([]);

  const [applyEnabled, setApplyEnabled] = useState(false);

  const [loadingCharts, setLoadingCharts] = useState([]);

  const [count, setCount] = useState(0);

  useEffect(() => {
    setLoadedCharts(null);

    async function loadDashboard() {
      let response = await Module.getModuleByName("Performance");
      setModuleId(response.data.modules._id);
      if (configs == null) {
        await loadConfigs(response.data.modules._id);
      }
    }
    loadDashboard().then(r => console.log("Filter Loaded"));
  }, [appliedFilters]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const flipCapabilityFlag = (name) => {
    let newFlags = { ...enabledCapabilities };

    newFlags[name] = !(newFlags[name] ?? false);


    for(let [key, value] of Object.entries(newFlags)) {
      if(key !== name)
        newFlags[key] = false;
    }

    let allDisabled = Object.values(newFlags).find(f => f === true) != null;
    setApplyEnabled(allDisabled);
    setEnabledCapablities(newFlags);
  }

  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const loadConfigs = async () => {
    let configsResponse = await TestConfig.getConfigs();
    let mappedConfigs = {};
    for (let config of configsResponse.data.configurations) {
      if (!(config.type in mappedConfigs)) {
        mappedConfigs[config.type] = [];
      }
      mappedConfigs[config.type].push(config);
    }

    let defaultAppliedConfigs = {};
    for (let cap of mappedConfigs.Capability) {
      defaultAppliedConfigs[cap.name] = false;
    }

    setEnabledCapablities(defaultAppliedConfigs);
    setConfigs(mappedConfigs);
  };

  const onFilterChanged = (name, value) => {
    let newFilters = { ...selectedFilters };
    if (value == null) {
      delete newFilters[name];
    } else {
      newFilters[name] = value;
    }

    setSelectedFilters(newFilters);
  };

  const onDateChanged = (newDateRange) => {
    setDateRange(newDateRange);
  };


  if (!isLoggedIn) {
    return <Navigate replace to="/sign-in" />;
  }

  const getFiltersHeader = () => {
    return <>
      <CardHeader title="Filter"
        action={
          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <SelectFilters
            dashboardId={moduleId}
            fromDate={dateRange[0]}
            toDate={dateRange[1]}
            filters={configs}
            onChange={onFilterChanged}
            onDateChange={onDateChanged}
          />
          <BoxCapabilities capabilities={configs?.Capability} flagsMap={enabledCapabilities} onChange={flipCapabilityFlag} />
          <ArgonButton
            disabled={!applyEnabled}
            onClick={() => setAppliedFilters({ "enabledCapabilities": enabledCapabilities, "selectedFilters": selectedFilters, "dateRange": dateRange })}
            variant="gradient"
            color="info"
            fullWidth>
            Apply
          </ArgonButton>
        </CardContent>
      </Collapse>
    </>;
  };

  return (
    <DashboardLayout>
      <Header />
      <ArgonBox mt={10}>
        <Card>
          <Grid container p={1}>
            <Grid item xs={12} textAlign="center">
              <ArgonTypography variant="h2">Performance</ArgonTypography>
            </Grid>
          </Grid>
        </Card>
      </ArgonBox>
      <ArgonBox mt={5}>
        <Grid container mt={5}>
          <Grid item xs={12}>
            <Card>
              {configs == null ? loading() : getFiltersHeader()}
            </Card>
          </Grid>
        </Grid>
      </ArgonBox>
      <Footer />
    </DashboardLayout >
  );
}


export default PerformancePage;
